const G08SpreadInfo = {
  sheetName: 'G08',
  headerRowCount: 2,
  headerColumnCount: 24,
  headerColumns: [
    {
      index: 0,
      name: '清单项目编号',
      width: '110'
    },
    {
      index: 1,
      name: '工程或费用项目名称',
      width: '280'
    },
    {
      index: 2,
      name: '单位',
      width: '60'
    },
    {
      index: 3,
      name: '单价',
      width: '60'
    },
    {
      index: 4,
      name: '合同数量',
      children: [
        {
          index: 4,
          name: '原合同数量',
          width: '90'
        },
        {
          index: 5,
          name: '按施工图调整后数量',
          width: '90'
        },
        {
          index: 6,
          name: '变更数量',
          width: '90'
        },
        {
          index: 7,
          name: '变更后数量',
          width: '90'
        },
        {
          index: 8,
          name: '工作量（元）',
          width: '90'
        }
      ]
    },
    {
      index: 9,
      name: '本年度计划工程量',
      children: [
        {
          index: 9,
          name: '工程量',
          width: '90'
        },
        {
          index: 10,
          name: '工作量（元）',
          width: '90'
        },
        {
          index: 11,
          name: '占合同量%',
          width: '90'
        }
      ]
    },
    {
      index: 12,
      name: '月份计划工程量',
      children: [
        {
          index: 12,
          name: '1月',
          width: '60'
        },
        {
          index: 13,
          name: '2月',
          width: '60'
        },
        {
          index: 14,
          name: '3月',
          width: '60'
        },
        {
          index: 15,
          name: '4月',
          width: '60'
        },
        {
          index: 16,
          name: '5月',
          width: '60'
        },
        {
          index: 17,
          name: '6月',
          width: '60'
        },
        {
          index: 18,
          name: '7月',
          width: '60'
        },
        {
          index: 19,
          name: '8月',
          width: '60'
        },
        {
          index: 20,
          name: '9月',
          width: '60'
        },
        {
          index: 21,
          name: '10月',
          width: '60'
        },
        {
          index: 22,
          name: '11月',
          width: '60'
        },
        {
          index: 23,
          name: '12月',
          width: '60'
        }
      ]
    }
  ]
}

export default G08SpreadInfo
