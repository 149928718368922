import RestService from '@/services/RestService'
import axios from 'axios'
import utility from '@/common/utility'
import auth from '@/common/auth'

let resourceName = 'g07'

let batchAddUrl = 'batchAdd'

class G07Service extends RestService {
  batchAdd (g07Models) {
    g07Models.forEach(g07Model => {
      g07Model.generatePrimaryKey()
    })
    return axios.post(utility.getRestFullUrl(this.resourceName, batchAddUrl), g07Models, {
      headers: {
        'Authorization': utility.getAuthHeader(auth.getToken())
      }
    })
  }

  deleteAll (tenderGuid) {
    return axios.delete(utility.getRestFullUrl(this.resourceName, 'deleteAll', tenderGuid), {
      headers: {
        'Authorization': utility.getAuthHeader(auth.getToken())
      }
    })
  }
}

const g07Service = new G07Service(resourceName)

export default g07Service
